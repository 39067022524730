import assets from '../../assets';
import styles from './styles/loading.module.scss';
export default () => {
  return (
    <div className={styles.container}>
      <span className={styles.title}>Loading</span>
      <img src={assets.LOADING} className={styles.loading} />
    </div>
  );
};
