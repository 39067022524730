import { createRef } from 'react';
import { Types } from '../generated/types';

const alertRef = createRef<{ show?: (params: Types.IAlert) => void; close?: () => void }>();

const Alert = {
  show: (params: Types.IAlert) => {
    if (alertRef.current && alertRef.current.show) {
      alertRef.current?.show(params);
    }
  },
  close: () => {
    if (alertRef.current && alertRef.current.close) {
      alertRef.current?.close();
    }
  },
  unknown: (status?: number, button?: Types.IButton) => {
    if (alertRef.current && alertRef.current.show) {
      alertRef.current?.show({
        status,
        button,
        message: 'មានភាពមិនប្រក្រតីកើតឡើង។',
      });
    }
  },
};

export { alertRef, Alert };
