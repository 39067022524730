import moment from 'moment';
import { Types } from '../generated/types';
const Formats = {
  id: (number: any) => {
    if (!!number && number.toString().length <= 7) {
      const id = number.toString();
      return id.length > 7 ? id : `000000${id}`.slice(-7);
    }
    return number;
  },
  currency: (currency: any) => {
    if (!!currency) {
      return currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return currency;
  },
  fraction: (number: any) => {
    if (!!number) {
      return parseFloat(number)
        .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        .toString();
    }
    return '0.00';
  },
  round: (number: any, precision: number) => {
    if (!!number && !!number.toString().trim()) {
      const factor = Math.pow(10, precision);
      const result = Math.round(number * factor) / factor;
      return result;
    }
    return 0;
  },
  roundCurrency: (currency: any, round = 2) => {
    return Formats.currency(Formats.round(currency, round));
  },
  date: (date: string, format: Types.IDateFormat = 'DD-MM-YYYY') => {
    return moment(date).format(format);
  },
  time: (date: string, format: Types.ITimeFormat = 'HH:mm') => {
    return moment(date).format(format);
  },
  dateTime: (datetime: string, format: Types.IDateTimeFormat = 'DD-MM-YYYY hh:mm A') => {
    return moment(datetime.replace(' ', 'T')).format(format);
  },
  phoneNumber: (number: any) => {
    if (!!number) {
      const ph = number.toString().substring(0, 1);
      const h = number.toString().substring(0, 3);
      return ph == '0' || ph == '+' || h == '855' ? number : `0${number}`;
    }
    return number;
  },
  firstCapital: (letter: any) => {
    if (!!letter && !!letter.toString().trim()) {
      return `${letter.toString().charAt(0).toLocaleUpperCase()}${letter.toString().slice(1)}`;
    }
    return letter;
  },
  price: (number: any) => {
    return (Formats.currency(Formats.round(Number(number) || 0, 3)) || '0').toString();
  },
  shortCurrency: (currency: any) => {
    const str: string = currency.toString();
    const length = str.length;
    if (length > 9) {
      return `${str.substring(0, length - 9)}B`;
    } else if (length > 6) {
      return `${str.substring(0, length - 6)}M`;
    } else if (length > 3) {
      return `${str.substring(0, length - 3)}K`;
    }
    return str;
  },
};

export default Formats;
