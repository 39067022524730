import moment from 'moment';
import { useEffect, useState } from 'react';
import api from '../api';
import assets from '../assets';
import LottoBall from '../components/lotto/LottoBall';
import LottoLive from '../components/lotto/LottoLive';
import LottoResult from '../components/lotto/LottoResult';
import { useAuthContext } from '../contexts/AuthProvider';
import { Types } from '../generated/types';
import Setting from '../utils/Setting';
import styles from './styles/lotto.module.scss';
import { clearTimeout, setTimeout } from 'worker-timers';

export default () => {
  const { auth } = useAuthContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [result, setResult] = useState<Types.IResult | null>(null);

  const [ball, setBall] = useState<string>('');
  const [index, setIndex] = useState<number>(-1);
  const [jackpot, setJackpot] = useState<string[]>([]);
  const [localTimestamp, setLocalTimestamp] = useState<number>(0);

  const [playingTime, setPlayingTime] = useState<number>(0);
  const [totalPlayingTime, setTotalPlayingTime] = useState<number>(0);

  const getRelease = async () => {
    const { response, error } = await api.result.release();
    const res: { result: Types.IResult } = response;
    const rs = res.result;

    loading && setLoading(false);

    const end = Number(rs.end_at);
    const start = Number(rs.start_at);
    const request = Number(rs.request_at);
    const _jackpot = rs.jackpot?.split(',') || [];
    const WAITING_RESULT = rs.dev_mode ? Setting.WAITING__DEV_RESULT : Setting.WAITING__PRO_RESULT;

    const _totalPlayingTime = end - start;
    const spendingTime = request - start;
    const _playingTime = _totalPlayingTime - spendingTime;

    if (_playingTime < WAITING_RESULT) {
      setIndex(-1);
      setJackpot([]);
    } else {
      const count =
        Math.round(Math.abs(_totalPlayingTime - _playingTime) / Setting.RELEASE_RESULT_TIME) - Setting.DELAY_RESULT;
      const length = count > 6 ? 6 : count;
      const _jp = length < 0 ? [] : _jackpot.slice(0, length);
      console.log(_jp, count);
      if (length >= 0 && length < 6 && length + 1 > jackpot.length) {
        setIndex(length);
      }
      setJackpot(_jp);
    }

    setResult(rs);
    setPlayingTime(_playingTime);
    setTotalPlayingTime(_totalPlayingTime);
    setLocalTimestamp(moment().unix());
    if (error) {
      setTimeout(() => getRelease(), 5000);
    }
  };

  const updateJackpot = (_jackpot: string[]) => {
    setJackpot(_jackpot);
  };

  const onCountDown = () => {
    if (result) {
      const nowTimestamp = moment().unix();
      const timestamp = nowTimestamp - localTimestamp;
      const playing = playingTime - timestamp;
      console.log('play', playing);
      const WAITING_RESULT = result.dev_mode ? Setting.WAITING__DEV_RESULT : Setting.WAITING__PRO_RESULT;
      if (playing < WAITING_RESULT) {
        setJackpot([]);
      } else {
        const _jackpot = result.jackpot?.split(',') || [];
        const count =
          Math.round(Math.abs(totalPlayingTime - playing) / Setting.RELEASE_RESULT_TIME) - Setting.DELAY_RESULT;
        if (jackpot.length < 6 && count <= 6) {
          const length = count > 6 ? 6 : count;
          const _jp = length < 0 ? [] : _jackpot.slice(0, length);
          console.log(count);
          if (length >= 0 && length < 6 && length + 1 > jackpot.length) {
            setIndex(length);
          }
          if (jackpot.length !== _jp.length) {
            setJackpot(_jp);
          }
        }
      }
      setPlayingTime(playing);
      setLocalTimestamp(moment().unix());
    }
  };

  useEffect(() => {
    if (result && !loading) {
      const WAITING_RESULT = result.dev_mode ? Setting.WAITING__DEV_RESULT : Setting.WAITING__PRO_RESULT;
      const RELOAD_RESULT = WAITING_RESULT - 10;
      if (playingTime <= WAITING_RESULT && playingTime > RELOAD_RESULT) {
        const timer = setTimeout(() => window.location.reload(), 10000);
        return () => clearTimeout(timer);
      } else if (playingTime < RELOAD_RESULT || playingTime > totalPlayingTime - Setting.TOTAL_RELEASE_RESULT_TIME) {
        console.log('count');
        const timer = setTimeout(() => (playingTime > 0 ? onCountDown() : getRelease()), 1000);
        return () => clearTimeout(timer);
      } else {
        const seconds = playingTime % 60;
        console.log('refresh', seconds);
        const timer = setTimeout(() => getRelease(), (seconds <= 0 ? 60 : seconds) * 1000);
        return () => clearTimeout(timer);
      }
    } else if (!result && !loading) {
      window.location.reload();
    }
  }, [result, loading, playingTime, totalPlayingTime]);

  useEffect(() => {
    if (jackpot.length > 0 && !!result) {
      setBall('');
    }
    if (jackpot.length === 10) {
      setIndex(-1);
    }
  }, [jackpot]);

  useEffect(() => {
    const _jackpot = result?.jackpot?.split(',') || [];
    if (index >= 0 && _jackpot.length > 0) {
      const r = _jackpot[index];
      const timer = setTimeout(() => setBall(r), 3000);
      return () => clearTimeout(timer);
    }
  }, [index]);

  useEffect(() => {
    getRelease();
  }, []);

  return (
    <div className={styles.container}>
      <button onClick={() => window.open(auth ? '/result' : '/login', '_self')} className={styles.login}>
        {auth ? 'Dashboard' : 'Login'}
      </button>
      <img src={assets.BALL} className={styles.ball} />
      <div className={styles.content}>
        <div className={styles.lotto}>
          <span className={styles.title}>លទ្ធផលចេញថ្ងៃទី: </span>
          <LottoResult ball={ball} jackpot={jackpot} loading={loading} />
          <LottoBall jackpot={jackpot} />
        </div>
        <LottoLive />
      </div>
    </div>
  );
};
