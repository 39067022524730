import { Types } from '../generated/types'
import Formats from './Formats'

const Validator = {
  isNotNull: (variable: any) => {
    return variable !== null && !!variable && !!variable.toString().trim()
  },
  isEmail: (email: any) => {
    const exp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return exp.test(String(email).toLowerCase())
  },
  isNumber: (number: any) => {
    return !/[^0-9]/.test(number)
  },
  isDecimal: (number: any) => {
    if (Validator.isNotNull(number)) {
      const format = number.toString().replace(',', '.')
      if (!/^\d+[.]?\d*$/.test(format)) {
        return false
      }
    }
    return number
  },
  isInvalid: (variable: any, replace: any = null) => {
    return Validator.isNotNull(variable) ? variable : replace
  },
  isPhone: (phone: string) => {
    const ph = phone.toString().substring(0, 1)
    const h = phone.toString().substring(0, 3)
    return ph == '0' || ph == '+' || h == '855'
  },
  isSameCoord: (origin: Types.ICoord, latestOrigin: Types.ICoord) => {
    return (
      Formats.round(origin.lat, 6) === Formats.round(latestOrigin.lat, 6) &&
      Formats.round(origin.lng, 6) === Formats.round(latestOrigin.lng, 6)
    )
  },
  isVideo: (fileName: string) => {
    const [extension] = fileName.substring(fileName.lastIndexOf('.')).split('?')
    return (
      extension.includes('mp4') ||
      extension.includes('mov') ||
      extension.includes('mkv') ||
      extension.includes('m4v') ||
      extension.includes('avi')
    )
  },
}

export default Validator
