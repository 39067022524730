import styles from './styles/result.module.scss';
import assets from '../../assets';
import { memo, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Types } from '../../generated/types';
import Setting from '../../utils/Setting';

interface IProps {
  ball: string;
  loading?: boolean;
  jackpot: string[];
}

const LottoBall = memo<any>(({ result = 0 }) => {
  if (result > 0) {
    const isOdd = (Math.floor(Math.random() * 10) + 1) % 2 !== 0;
    return (
      <div className={`${styles.result} ${isOdd ? styles.resultOdd : styles.resultEven}`}>
        <img src={assets.BALL} style={{ width: '50%' }} />
        <span>{`0${result}`.slice(-2)}</span>
      </div>
    );
  }
  return null;
});

export default (props: IProps) => {
  const jackpot = useMemo(() => props.jackpot || [], [props.jackpot]);
  const isWaiting = jackpot.length != 6;

  return (
    <div className={styles.container}>
      <div className={styles.sphere}>
        <img src={isWaiting ? assets.RANDOM : assets.WAITING} className={styles.loading} />
        <img src={assets.SPHERE} className={styles.glass} />
        <img src={assets.SHINE} className={styles.shine} />
        <div className={styles.box}>
          <img src={assets.STICK} className={`${styles.stick} ${isWaiting ? styles.spin : ''}`} />
          <img src={assets.ROUND_LOGO} className={styles.logo} />
        </div>
        <LottoBall result={Number(props.ball || 0)} />
      </div>
    </div>
  );
};
