import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styles from './styles/alert.module.scss';
import { Types } from '../../generated/types';
// import { useAuthContext } from '../../contexts/AuthProvider';

const SUCCESS = '#00dc09';
const ERROR = '#e10d0d';

export default forwardRef((props?: any, ref?: any) => {
  // const { logoutAuth } = useAuthContext();
  const [visible, setVisible] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [color, setColor] = useState(ERROR);
  const [btn, setBtn] = useState<Types.IButton | undefined>(undefined);

  const onClose = () => {
    !!btn?.onPress && btn.onPress();
    if (!!btn?.logout) {
      // logoutAuth();
      window.location.reload();
    }
    setMessage('');
    setBtn(undefined);
    setVisible(false);
    setColor(ERROR);
  };

  const onShow = (params: Types.IAlert) => {
    const status = params.status;
    if (status === 401 || status === 411 || status === 419) {
      setMessage('មានឧបករណ៍ផ្សេងលុកចូលគណនីនេះ / សុវត្តិភាពបានហួសសពុលភាព។');
      setBtn({ title: 'សូមចូលម្ដងទៀត', logout: true });
    } else {
      setMessage(params.message || '');
      setBtn(params.button);
      setColor(params.type === 'SUCCESS' ? SUCCESS : ERROR);
    }
    setVisible(true);
  };

  useImperativeHandle(ref, () => ({
    show: onShow,
    close: onClose,
  }));

  useEffect(() => {
    if (!btn?.onPress || !btn.logout) {
      const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape' && visible) {
          onClose();
        }
      };
      document.addEventListener('keydown', onKeyDown);
      return () => document.removeEventListener('keydown', onKeyDown);
    }
  }, [btn, onClose]);

  if (visible) {
    return (
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.card}>
            <div className={styles.wrapper}>
              <span className={styles.message}>{message}</span>
            </div>
            <button className={styles.btn} style={{ backgroundColor: color }} onClick={onClose}>
              {btn?.title || 'យល់ព្រម'}
            </button>
          </div>
        </div>
      </div>
    );
  }
  return null;
});
