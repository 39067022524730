import { useEffect, useRef, useState } from 'react';
import api from '../api';
import assets from '../assets';
import ResultDialog from '../components/results/ResultDialog';
import { useAuthContext } from '../contexts/AuthProvider';
import { Types } from '../generated/types';
import { Alert } from '../hooks/useAlert';
import { Formats } from '../utils';
import styles from './styles/result.module.scss';
export default () => {
  const refResult = useRef({ show: () => {} });
  const { setAuth } = useAuthContext();
  const [data, setData] = useState<Types.IResult[]>([]);
  const [loading, setLoading] = useState(true);

  const onGetList = async () => {
    const { response, error } = await api.result.list();
    if (response?.data) {
      setData(response.data || []);
    }
    if (error) {
      Alert.show({ type: 'ERROR', message: error.message, status: error.status });
    }
    setLoading(false);
  };

  const onLogout = async () => {
    await api.auth.logOut();
    setAuth(null);
  };

  useEffect(() => {
    onGetList();
  }, []);

  const renderHeader = () => {
    return (
      <nav className={styles.header}>
        <a href="/" className={styles.title}>
          លទ្ធផលឆ្នោត ឡូតូ​ 6/39
        </a>
        <button className={styles.logout} onClick={onLogout}>
          Logout
        </button>
      </nav>
    );
  };

  return (
    <div className={styles.container}>
      {renderHeader()}
      <div className={styles.content}>
        <div className={styles.info}>
          <span className={styles.title}>បញ្ជីលទ្ធផល</span>
          {!loading && (
            <button className={styles.create} onClick={() => refResult.current.show()}>
              ចេញលទ្ធផល
            </button>
          )}
        </div>
        <table className={styles.list}>
          <thead>
            <tr>
              <th>លេខសម្គាល់</th>
              <th>លទ្ធផលឆ្នោត</th>
              <th>ម៉ោងចាប់ផ្ដើម</th>
              <th>ម៉ោងបញ្ចប់</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={5}>
                  <img src={assets.LOADING} /> Loading <img src={assets.LOADING} />
                </td>
              </tr>
            )}
            {data.map((item, i) => (
              <tr key={i}>
                <td>#{Formats.id(item.id)}</td>
                <td>
                  {item.jackpot.split(',').map((jp, j) => (
                    <span key={j}>{jp}</span>
                  ))}
                </td>
                <td>{Formats.dateTime(item.start_at, 'DD-MM-YYYY HH:mm:ss')}</td>
                <td>{Formats.dateTime(item.end_at, 'DD-MM-YYYY HH:mm:ss')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ResultDialog ref={refResult} onReload={onGetList} />
    </div>
  );
};
