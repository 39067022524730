import assets from '../../assets';
import styles from './styles/ball.module.scss';

interface IProps {
  jackpot: string[];
}

export default (props: IProps) => {
  const jackpots = [...props.jackpot, ...Array(6 - props.jackpot.length).fill('')];

  return (
    <div className={styles.container}>
      {jackpots.map((jp, i) => (
        <div key={i} className={styles.content}>
          {jp ? (
            <div className={styles.ball}>
              <span className={styles.result}>{jp}</span>
              <img src={assets.BALL} className={styles.icon} />
            </div>
          ) : (
            <div className={styles.ball}></div>
          )}
          <div className={styles.shadow}></div>
        </div>
      ))}
    </div>
  );
};
