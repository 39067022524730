import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import moment from 'moment';
import { Alert } from '../hooks/useAlert';

const Init = axios.create({
  baseURL:
    process.env.REACT_APP_DEVELOP_MODE == '0' ? `https://${window.location.host}` : process.env.REACT_APP_API_URL,
  timeout: 100000,
  validateStatus: status => status >= 200 && status < 300,
});

Init.interceptors.request.use(
  AxiosRequestConfig => {
    AxiosRequestConfig.headers['Accept'] = 'application/json';
    AxiosRequestConfig.headers['content-type'] = 'application/json';
    let cookieArray = document.cookie.split(';');
    // this can probably be improved by using a regex.. but this works for now
    for (var i = 0; i < cookieArray.length; i++) {
      let cookiePair = cookieArray[i].split('=');

      if (cookiePair[0].trim() == 'token-live') {
        AxiosRequestConfig.headers['Authorization'] = `Bearer ${decodeURIComponent(cookiePair[1])}`;
      }
    }
    return AxiosRequestConfig;
  },
  function (error) {
    return Promise.reject(error);
  },
);

delete Init.defaults.headers.common['X-XSRF-TOKEN'];

Init.interceptors.response.use(
  response => {
    // save token to cookie
    const halfDay = 12 * 60 * 60 * 1000;
    if (response.config.url == '/api/login' && response.status === 200) {
      var expires = '';
      var date = new Date();
      date.setTime(date.getTime() + halfDay + response?.data?.expires_in ?? 0);
      expires = '; expires=' + date.toUTCString();
      console.log(expires);
      document.cookie = 'token-live' + '=' + (response?.data?.access_token || '') + expires + '; Path=/';
    }
    //=========
    if (response?.data?.status === 'success' || response.status === 200) {
      if (response?.data?.data) return response.data.data;
      return response.data;
    }

    return Promise.reject({ message: 'មានភាពមិនប្រក្រតីកើតឡើង', status: 404 });
  },
  (error: AxiosError<{ message?: string }>) => {
    const status = error.response?.status;
    const disabledAlert = error.config?.headers?.DISABLED_ALERT === 'true';
    if (!disabledAlert && (status === 401 || status === 411 || status === 419)) {
      // remove token to cookie
      var cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf('=');
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }
      // ======================
      Alert.show({
        message: 'មានឧបករណ៍ផ្សេងលុកចូលគណនីនេះ / សុវត្តិភាពបានហួសសពុលភាព។',
        button: { title: 'សូមចូលម្ដងទៀត', logout: true },
      });
    }
    return Promise.reject({
      status,
      message: error?.response?.data?.message || error.message,
    });
  },
);

function request<D = any, R = any>(config: AxiosRequestConfig<D>) {
  const start = moment().unix();
  return Init.request<R, R, D>(config).then(
    response => {
      const end = moment().unix();
      return { error: null, response, responseTime: end - start };
    },
    (error: AxiosError) => {
      const end = moment().unix();
      return { error, response: null, responseTime: end - start };
    },
  );
}

export default class Axios {
  static get<D = any, R = any>(url: string, data?: D, config?: AxiosRequestConfig<D>) {
    const query = Object.entries(data || {})
      .map(x => `${x[0]}=${x[1]}`)
      .join('&');
    return request<D, R>({
      ...config,
      method: 'get',
      withCredentials: true,
      url: `${url}${!!query ? '?' : ''}${query}`,
    });
  }

  static delete<D = any, R = any>(url: string, config?: AxiosRequestConfig<D>) {
    return request<D, R>({
      ...config,
      url,
      method: 'delete',
    });
  }

  static post<D = any, R = any>(url: string, config?: AxiosRequestConfig<D>) {
    return request<D, R>({
      ...config,
      url,
      method: 'post',
    });
  }

  static put<D = any, R = any>(url: string, config?: AxiosRequestConfig<D>) {
    return request<D, R>({
      ...config,
      url,
      method: 'put',
    });
  }
}
