import { Types } from '../../generated/types';
import axios from '../axios';

interface logIn {
  username: string;
  password: string;
}

export default () => ({
  csrf: () => axios.get('/sanctum/csrf-cookie'),
  user: (disabledAlert: boolean = false) =>
    axios.get<{}, { auth: Types.IAuth }>('/api/user', {}, { headers: { DISABLED_ALERT: disabledAlert } }),
  logOut: () => axios.post('/api/logout'),
  logIn: (data: logIn) => axios.post<logIn>('/api/login', { data }),
});
