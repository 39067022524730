import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import api from '../api';
import assets from '../assets';
import { useAuthContext } from '../contexts/AuthProvider';
import { Alert } from '../hooks/useAlert';
import styles from './styles/login.module.scss';

export default () => {
  const navigate = useNavigate();
  const { auth, getAuth } = useAuthContext();
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // api.auth.csrf();
    if (auth) {
      navigate('/result');
    }
  }, []);

  const onLogin = async () => {
    if (!username) {
      usernameRef.current?.focus();
    } else if (!password) {
      passwordRef.current?.focus();
    } else {
      setLoading(true);
      const { response, error } = await api.auth.logIn({ username, password });
      if (response) {
        getAuth();
      }
      if (error) {
        setPassword('');
        Alert.show({
          message: 'Username/Passwors is incorrect.',
          button: { title: 'Retry', onPress: () => window.location.reload() },
        });
      }
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <form className={styles.card}>
        {loading && <img src={assets.LOADING} className={styles.loading} />}
        <span className={styles.title}>MEMBER LOGIN</span>
        <input
          autoFocus
          type="text"
          value={username}
          ref={usernameRef}
          placeholder="Username"
          className={styles.input}
          onChange={t => setUsername(t.target.value)}
        />
        <input
          type="password"
          value={password}
          ref={passwordRef}
          placeholder="Password"
          className={styles.input}
          onChange={t => setPassword(t.target.value)}
        />
        <button
          disabled={loading}
          onClick={onLogin}
          className={styles.button}
          style={loading ? { backgroundColor: '#AAA' } : {}}>
          LOGIN
        </button>
      </form>
    </div>
  );
};
