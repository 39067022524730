import { useState, forwardRef, useImperativeHandle } from 'react';
import api from '../../api';
import assets from '../../assets';
import { Alert } from '../../hooks/useAlert';
import { Validator } from '../../utils';
import styles from './styles/dialog.module.scss';

interface IProps {
  onReload?: () => void;
}

export default forwardRef((props: IProps, ref: any) => {
  const DUMP = process.env.NODE_ENV === 'development' ? '' : '';
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [num1, setNum1] = useState<string>(DUMP);
  const [num2, setNum2] = useState<string>(DUMP);
  const [num3, setNum3] = useState<string>(DUMP);
  const [num4, setNum4] = useState<string>(DUMP);
  const [num5, setNum5] = useState<string>(DUMP);
  const [num6, setNum6] = useState<string>(DUMP);

  const nums = [num1, num2, num3, num4, num5, num6];

  const onChangeText = (num: string, func: (f: string) => void) => {
    if (
      num === '' ||
      (Validator.isNumber(num) &&
        ((num.length === 1 && Number(num) <= 3) || (num.length === 2 && Number(num) < 40)) &&
        (num.length === 1 || (num.length === 2 && !nums.includes(num))))
    ) {
      func(num);
    }
  };

  const onClose = () => {
    setVisible(false);
    setNum1('');
    setNum2('');
    setNum3('');
    setNum4('');
    setNum5('');
    setNum6('');
  };

  const onCreating = async (jackpot: string) => {
    const { response, error } = await api.result.create(jackpot);
    if (response && props.onReload) {
      props.onReload();
      onClose();
    }
    if (error) {
      Alert.show({ type: 'ERROR', message: error.message, status: error.status });
    }
    setLoading(false);
  };

  const onSubmit = () => {
    const jackpot = `${num1},${num2},${num3},${num4},${num5},${num6}`;
    if (jackpot.length === 17) {
      setLoading(true);
      onCreating(jackpot);
    } else {
      window.alert('Invalid data.');
    }
  };

  useImperativeHandle(ref, () => ({
    show: () => setVisible(true),
  }));

  if (visible) {
    return (
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={styles.header}>
            <span className={styles.title}>កំណត់លទ្ធផល</span>
            {loading ? (
              <img src={assets.LOADING} className={styles.loading} />
            ) : (
              <button className={styles.close} onClick={onClose}>
                X
              </button>
            )}
          </div>
          <div className={styles.box}>
            <input
              autoFocus
              minLength={2}
              maxLength={2}
              className={styles.input}
              value={num1}
              onChange={t => onChangeText(t.target.value, setNum1)}
            />
            <input
              minLength={2}
              maxLength={2}
              className={styles.input}
              value={num2}
              onChange={t => onChangeText(t.target.value, setNum2)}
            />
            <input
              minLength={2}
              maxLength={2}
              className={styles.input}
              value={num3}
              onChange={t => onChangeText(t.target.value, setNum3)}
            />
            <input
              minLength={2}
              maxLength={2}
              className={styles.input}
              value={num4}
              onChange={t => onChangeText(t.target.value, setNum4)}
            />
            <input
              minLength={2}
              maxLength={2}
              className={styles.input}
              value={num5}
              onChange={t => onChangeText(t.target.value, setNum5)}
            />
            <input
              minLength={2}
              maxLength={2}
              className={styles.input}
              value={num6}
              onChange={t => onChangeText(t.target.value, setNum6)}
            />
          </div>
          <div className={styles.footer}>
            <button disabled={loading} onClick={onClose}>
              បោះបង់
            </button>
            <button disabled={loading} onClick={onSubmit} style={loading ? { backgroundColor: '#AAA' } : {}}>
              កំណត់លទ្ធផល
            </button>
          </div>
        </div>
      </div>
    );
  }
  return null;
});
