import pkg from '../../package.json';
export default {
  LOGO: `/assets/logo.png?${pkg.version}`,
  ROUND_LOGO: `/assets/round-logo.png?${pkg.version}`,
  BALL: `/assets/ball.webp?${pkg.version}`,
  LOADING: `/assets/loading.gif?${pkg.version}`,
  STICK: `/assets/stick.webp?${pkg.version}`,
  SHINE: `/assets/shine.webp?${pkg.version}`,
  SPHERE: `/assets/sphere.webp?${pkg.version}`,
  RANDOM: `/assets/random1.gif?${pkg.version}`,
  WAITING: `/assets/waiting1.gif?${pkg.version}`,
};
