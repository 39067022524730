import { memo } from 'react';
import assets from '../../assets';
import styles from './styles/live.module.scss';

export default memo(() => {
  return (
    <div className={styles.container}>
      <img src={assets.LOGO} className={styles.logo} />
      <div className={styles.avatar}></div>
    </div>
  );
});
