import { BrowserRouter as Router } from 'react-router-dom';
import AuthProvider from './contexts/AuthProvider';
import { MainRouter } from './routes';

function App() {
  return (
    <div className="main">
      <Router>
        <AuthProvider>
          <MainRouter />
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
