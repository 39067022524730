import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loading from '../components/loadings/Loading';
import LoginScreen from '../screens/LoginScreen';
import LottoScreen from '../screens/LottoScreen';
import ResultScreen from '../screens/ResultScreen';

export const MainRouter = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route index path="/" element={<LottoScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/result" element={<ResultScreen />} />
      </Routes>
    </Suspense>
  );
};
