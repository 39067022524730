import React, { useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router';
import api from '../api';
import AlertDialog from '../components/alerts/AlertDialog';
import Loading from '../components/loadings/Loading';
import { Types } from '../generated/types';
import { alertRef } from '../hooks/useAlert';
import Setting from '../utils/Setting';

const ActionType = {
  SET_AUTH_PROVIDER: 'SET_AUTH_PROVIDER',
  SET_AUTH_LOGOUT_PROVIDER: 'SET_AUTH_LOGOUT_PROVIDER',
};

interface AuthProviderState {
  auth?: Types.IAuth | null;
}

interface AuthProviderAction {
  getAuth: () => void;
  logoutAuth: () => void;
  setAuth: (auth: any) => void;
}

interface AuthProvider extends AuthProviderState, AuthProviderAction {}

const Init: AuthProvider = {
  auth: null,
  getAuth: () => {},
  logoutAuth: () => {},
  setAuth: (auth: any) => {},
};

const AuthContext = React.createContext<AuthProvider>(Init);
const AuthProvider = AuthContext.Provider;
export default (props: Types.IContextChildren) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies, setCookies, removeCookies] = useCookies();
  const [loading, setLoading] = useState<boolean>(true);
  const isUnauthenticated = useMemo(() => Setting.PUBLIC_URL.includes(location.pathname), [location.pathname]);

  const [state, dispatch] = React.useReducer((preState: AuthProvider, action: Types.IContextAction): AuthProvider => {
    switch (action.type) {
      case ActionType.SET_AUTH_PROVIDER:
        return { ...preState, ...action.payload };
      case ActionType.SET_AUTH_LOGOUT_PROVIDER:
        return { ...preState, auth: null };
      default:
        return preState;
    }
  }, Init);

  const cxt: AuthProvider = useMemo(
    () => ({
      ...state,
      logoutAuth: async () => {
        setLoading(true);
        await api.auth.logOut();
        removeCookies('XSRF-TOKEN');
        setLoading(false);
        dispatch({ type: ActionType.SET_AUTH_LOGOUT_PROVIDER });
        // reload get new csrf token from blade
        window.location.reload();
      },
      setAuth: (auth: any) => {
        dispatch({
          payload: { auth },
          type: ActionType.SET_AUTH_PROVIDER,
        });
      },
    }),
    [state.auth],
  );

  const getAuth = async () => {
    const { response, error } = await api.auth.user(loading);
    if (response) {
      cxt.setAuth(response?.auth);
      if (location.pathname === '/login') {
        navigate('/result');
      }
    }
    loading && setLoading(false);
  };

  useEffect(() => {
    getAuth();
  }, []);

  useEffect(() => {
    if (!isUnauthenticated && !cxt.auth && !loading) {
      navigate('/login');
    }
  }, [cxt.auth, isUnauthenticated, loading]);

  const render = () => {
    if (loading) {
      return <Loading />;
    }
    return props.children;
  };

  return (
    <AuthProvider value={{ ...cxt, getAuth }}>
      {!loading && <AlertDialog ref={alertRef} />}
      {render()}
    </AuthProvider>
  );
};

export const useAuthContext = () => React.useContext(AuthContext);
